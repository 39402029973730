<template>
  <div>
    <section v-if="show === 'resetPassword'" id="resetPassword">
      <v-form ref="form" @submit.prevent="resetPassword">
        <v-container class="resetPassword_container">
          <v-row class="d-flex flex-column justify-center align-center">
            <h3 class="resetPassword-card__header">Reset Password</h3>
            <s-card class="resetPassword-card col-12" outlined>
              <v-row>
                <v-col class="mx-3">
                  <v-row class="">
                    <s-text-field
                      name="email"
                      label="Email Address"
                      onfocus="this.removeAttribute('readonly');"
                      readonly
                      filled
                      required
                      v-model="value.email"
                      :error-messages="getErrors('email', $v.value.email)"
                      @blur="$v.value.email.$touch()"
                    >
                    </s-text-field>
                  </v-row>
                  <v-row class="">
                    <s-text-field
                      name="code"
                      label="Code Number"
                      type="text"
                      filled
                      required
                      v-model="value.code"
                      :error-messages="getErrors('code', $v.value.code, 'code')"
                      @blur="$v.value.code.$touch()"
                    >
                    </s-text-field>
                  </v-row>
                  <v-row>
                    <s-text-field
                      name="newPassword"
                      label="New Password"
                      :append-icon="
                        showNewPasswordText ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :type="showNewPasswordText ? 'text' : 'password'"
                      @click:append="showNewPasswordText = !showNewPasswordText"
                      :error-messages="
                        getErrors('password', $v.value.newpassword)
                      "
                      @blur="$v.value.newpassword.$touch()"
                      filled
                      required
                      v-model="value.newpassword"
                    />
                  </v-row>
                  <v-row>
                    <s-text-field
                      name="Confirm Password"
                      label="Confirm Password"
                      :append-icon="
                        showPasswordText ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :type="showPasswordText ? 'text' : 'password'"
                      @click:append="showPasswordText = !showPasswordText"
                      :error-messages="
                        getErrors('password', $v.value.confirmPassword)
                      "
                      @blur="$v.value.newPassword.$touch()"
                      filled
                      required
                      v-model="value.confirmPassword"
                    />
                  </v-row>
                </v-col>
              </v-row>
              <s-btn
                testButton="submit-button"
                type="submit"
                color="primary"
                class="ma-0"
                elevation="0"
                :loading="isLoading"
                :disabled="invalidForm"
                block
              >
                Reset Password
              </s-btn>
            </s-card>
          </v-row>
        </v-container>
      </v-form>
    </section>
    <div style="height: 90vh" class="d-flex align-center" v-else>
      <reset-password-success action="Password Reset" />
    </div>
  </div>
</template>

<script>
import getErrors from '@/mixins/vuelidateErrors'
import { email, required, minLength, sameAs } from 'vuelidate/lib/validators'
import resetPasswordSuccess from '@/components/auth/ResetPasswordSuccess.vue'
export default {
  mixins: [getErrors],
  name: 'ResetNewPassword',
  components: {
    'reset-password-success': resetPasswordSuccess,
  },
  data() {
    return {
      show: 'resetPassword',
      value: {
        email: '',
        newpassword: '',
        confirmPassword: '',
        code: '',
      },
      showPasswordText: false,
      showNewPasswordText: false,
      isLoading: false,
    }
  },
  validations: {
    value: {
      email: {
        email,
        required,
      },
      newpassword: { required, minLength: minLength(8) },
      confirmPassword: { required, sameAsPassword: sameAs('newpassword') },
      code: { required },
    },
  },
  methods: {
    resetPassword() {
      if (this.invalidForm) {
        this.$v.$touch()
        return
      }
      this.isLoading = true
      this.$store
        .dispatch('auth/resetPassword', {
          ...this.value,
        })
        .then(() => {
          this.show = 'resetPasswordSuccess'
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  computed: {
    invalidForm() {
      return this.$v.$invalid
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
#resetPassword {
  @include center-children;

  & .resetPassword_container {
    @include auth-card-dimension;
  }
}

#logo {
  @include auth-logo;
}

.resetPassword-card {
  @include auth-card;
}

.resetPassword-card__header {
  @include auth-header;
}

.signup-link {
  margin-top: 18px;
}

.forgotpass-link {
  @include black-link;
  text-decoration: underline;
}
</style>
